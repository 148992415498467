import { Container, Row, Col } from "react-bootstrap";

import { GammaModel } from "../../models/GammaModel";

import GammaItem from "../GammaItem/GammaItem";

import "./Gamma.css";
import GammaCardItem from "../Cards/GammaCardItem/GammaCardItem";

interface Props {
  gammas: GammaModel[];
}

const Gamma = (props: Props) => {
  return (
    <article className="gamma mt-5 pt-5 pb-5">
      <Container className="gamma-wrapper" id="menu">
        <Row className="justify-content-center">
          {props.gammas.map((item, index) => {
            return (
              <Col
                xl={4}
                lg={4}
                md={6}
                sm={12}
                key={index}
                className="gamma-wrapper-col d-flex justify-content-center"
              >
                <GammaItem gamma={item} />
                {/* <GammaCardItem gamma={item} /> */}
              </Col>
            );
          })}
        </Row>
      </Container>
    </article>
  );
};

export default Gamma;