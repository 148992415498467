import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import SwiperCore, { Navigation, Mousewheel } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { MdArrowBack, MdArrowForward, MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import Slider from 'react-slick';

import { SubfamilyModel } from "../../models/SubfamilyModel";

import { breakpoints } from "../../data/SwiperData";

import VariantItem from "../VariantItem/VariantItem";

import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "./ProductAccessory.css";
import ProductModelResponse from "../../models/ProductModelResponse";
import ProductAccessoryItem from "../ProductAccessoryItem/ProductAccessoryItem";
import React, { useState } from "react";
import { Box, Grid, IconButton, Typography } from "@mui/material";

SwiperCore.use([Navigation, Mousewheel]);

interface Props {
  // subfamilies: SubfamilyModel[];
  products: ProductModelResponse[];
}

interface Settings {
  dots: boolean,
  speed: number,
  slidesToShow: number,
  slidesToScroll: number,
  infinite: boolean,
  autoplay: boolean,
  autoplaySpeed: number,
  prevArrow: any,
  nextArrow: any,
  responsive: any
}


const ProductAccessory = (props: Props) => {
  const slidesPerView = 4;
  const [showIndex, setShowIndex] = useState<number | null>(0);
  
  const handleIndex = (index: number) => {
    setShowIndex(index);
  }

  const NextArrow = ({ onClick }: { onClick: () => void }) => (
    <IconButton onClick={onClick} size="large" sx={{ position: 'absolute', top: '50%', right: -25, transform: 'translateY(-50%)' }}>
      <MdKeyboardArrowRight />
    </IconButton>
  );

  const PrevArrow = ({ onClick }: { onClick: () => void }) => (
    <IconButton onClick={onClick} size="large" sx={{ position: 'absolute', top: '50%', left: -25, transform: 'translateY(-50%)' }}>
      <MdKeyboardArrowLeft />
    </IconButton>
  );

  const settings: Settings = {
    dots: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 1000,
    nextArrow: <NextArrow />,
  
    prevArrow: <PrevArrow />,
    responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
};

  return (
    <article className="variant">
      <Container className="variant-wrapper">
        <Tab.Container defaultActiveKey={`${props.products[0].model}`}>
          <Row className="variant-wrapper-tabs justify-content-center mb-3">
            <Col
              xs={4}
              sm={4}
              md="auto"
              lg="auto"
              xl="auto"
              className="align-self-center"
            >
              <div className="variant-wrapper-tabs-button-prev">
                <MdArrowBack className="variant-wrapper-tabs-button-prev-icon" />
              </div>
            </Col>
            <Col
              xs={4}
              sm={4}
              md={10}
              lg={10}
              xl={10}
              className="variant-wrapper-tabs-col"
            >
              <Swiper
                mousewheel={
                  props.products.length > slidesPerView ? true : false
                }
                navigation={{
                  prevEl: ".variant-wrapper-tabs-button-prev",
                  nextEl: ".variant-wrapper-tabs-button-next",
                }}
                centerInsufficientSlides
                breakpoints={breakpoints(slidesPerView)}
              >
                {props.products.map((item, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <Nav variant="pills" className="justify-content-center">
                        <Nav.Item>
                          <Nav.Link eventKey={item.model} onClick={() => handleIndex(index)}>{`${item.model}`}</Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </Col>
            <Col
              xs={4}
              sm={4}
              md="auto"
              lg="auto"
              xl="auto"
              className="align-self-center text-right"
            >
              <div className="variant-wrapper-tabs-button-next">
                <MdArrowForward className="variant-wrapper-tabs-button-next-icon" />
              </div>
            </Col>
          </Row>
          {/* <Row className="mt-3" style={{ justifyContent: "center"}}> */}
            {/* <Col style={{ justifyContent: "center"}}> */}
              {/* <Tab.Content style={{ display: "flex"}}> */}
                {props.products.map((product, productIndex) => {
                  return (
                    showIndex === productIndex && (           
                    <Slider {...settings}>
                      {product?.accessoriesChilds?.map((accessory, index1) => (
                        <Box key={index1}>
                          <Box className={'img-body'} key={index1}>
                            <img src={accessory?.media?.url} alt="" />
                          </Box>
                          <Box key={index1}>
                            <Typography component={'h2'} variant={'h6'} fontSize={15}>
                              {accessory?.name}
                            </Typography>
                            <Typography component={'h3'} variant={'body2'}>
                              {accessory?.acessoryId}
                            </Typography>
                          </Box>
                        </Box>
                      ))}
                    </Slider>                            
                    )
                  )               
                })}
              {/* </Tab.Content> */}
            {/* </Col> */}
          {/* </Row> */}
        </Tab.Container>
      </Container>
    </article>
  );
};

export default ProductAccessory;
