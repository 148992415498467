import { useEffect, useState } from 'react';
import { Switch, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { saveAs } from "file-saver";
import { NameSpace, contactForm } from "../../data/AppLanguage";

import jsPDF from 'jspdf';
import style from "./DataSheetType2.module.css";
import html2canvas from 'html2canvas';
import logoMafirol from '../../assets/images/mafiroLogo.png';
import Util from "../../utils/Util";
import i18n from "../../i18n";
import mime from "mime-types";


const DataSheetType2 = ({dataSheetData}) => {
    const [isDataReady, setIsDataReady] = useState(false); 
    const { t } = useTranslation([NameSpace.COMMON]);
    const [isGeneratingPDF, setIsGeneratingPDF] = useState(true);

    useEffect(() => {
        if(dataSheetData.dataTable != null){
            setIsDataReady(true);     
        }
        
    }, [dataSheetData])

    const generatePDF = () => {
        try {
            if (!isDataReady) {
                console.error('Dados não prontos para gerar o PDF.');
                return;
              }
              setIsGeneratingPDF(true);

              const pdf = new jsPDF({compress: true, orientation: 'p', unit: 'mm', format: 'a4'});
        //   const pdf = new jsPDF('p', 'mm', 'a4');
      
            //   setTimeout(() => {
            const content1 = document.getElementById('pdf-content');
          html2canvas(content1, {
            scale: 3,
            useCORS: true,           
            // allowTaint: true,
          }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            // console.log('o que tem no imgdata é: ', imgData)
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
            pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      
            const pdfBlob = pdf.output('blob');

            saveAs(
              pdfBlob,
              `product_mafirol.${mime.extension(
                pdfBlob.type
              )}`
            );
          //   var url = URL.createObjectURL(pdfBlob);
          //   window.open(url, "_blank", "noopener, noreferrer");
          
          // saveAs(pdfBlob, 'product-Mafirol.pdf');
          

            setTimeout(() => {
              setIsGeneratingPDF(false);
            },1000)

         });         
        } catch (error) {
          console.error('Error generating PDF:', error);
          setIsGeneratingPDF(false);
        }
      };

      useEffect(() => {
        if (isDataReady) {
            generatePDF(1); // Gerar o PDF quando os dados estiverem prontos                              
        }
      }, [isDataReady]);

    return (
        <div>
        <div className={`${style.body}`} >                           
            <div id="pdf-content" className={style.container}>  
                <div id="watermark" className={`${style.watermark} ${style.ma} mt-4`}>                    
                    <p  className={`${style.watermark} ${style.ma}`} >MA</p>
                </div>  
                <div id="watermark" className={`${style.watermark}  ${style.fi}`}>                    
                    <p  className={`${style.watermark} ${style.fi}`} >FI</p>
                </div>  
                <div id="watermark" className={`${style.watermark} ${style.rol}`}>                    
                    <p  className={`${style.watermark} ${style.rol}`} >ROL</p>
                </div>    
                <div className='d-flex mb-2'>  
                    <div className='justify-content-start'>
                        <img 
                        className={style.imageColorHeader}
                        src={logoMafirol} />
                    </div>
                    <div  className={`${style.header} w-100 mt-4 ml-5`}>
                        <h1 className={style.h1}>{dataSheetData.title}</h1>
                    </div>
                 
                </div>        
            <hr className={`${style.separatorOne} ${style.hr}`}/>
            <h6 className={style.h6}>{dataSheetData.subtitle}</h6>
            <h5 className={style.h5}>{dataSheetData.subtitleText}</h5>

            <div className={style.parent}>
                <div className={style.sibilingOne}>
                    {dataSheetData.title1 != null && 
                    <div className={`${style.card} ${style.cardPosition01}`}>
                        <h5 className={style.cardTitle}>{dataSheetData.title1}</h5>
                        <hr className={`${style.separatorParagraph} ${style.hr}`}/>
                        <div className={style.paragraph}>
                            <span dangerouslySetInnerHTML={{ __html: dataSheetData.paragraph1 }}></span>
                        </div>
                    </div>
                    }
                    {dataSheetData.title2  && 
                    <div className={style.card}>
                        <h5 className={style.cardTitle}>{dataSheetData.title2}</h5>
                        <hr className={style.hr}/>
                        <p className={style.paragraph}>
                            <p dangerouslySetInnerHTML={{ __html: dataSheetData.paragraph2 }}></p>
                        </p>
                    </div>
                    }
                    {dataSheetData.title3 && 
                    <div className={style.card}>
                        <h5 className={style.cardTitle}>  {dataSheetData.title3}</h5>
                        <hr className={style.hr}/>
                        <p className={style.paragraph}>
                            <p dangerouslySetInnerHTML={{ __html: dataSheetData.paragraph3 }}></p>
                        </p>
                    </div>
                    }   
                    {/* {dataSheetData.title4 && 
                    <div className={style.card}>
                        <h5 className={style.cardTitle}>  {dataSheetData.title4}</h5>
                        <hr className={style.hr}/>
                        <p className={style.paragraph}>
                            <p dangerouslySetInnerHTML={{ __html: dataSheetData.paragraph4 }}></p>
                        </p>
                    </div>
                    }  */}
                    {/* {dataSheetData.title5 && 
                    <div className={style.card}>
                        <h5 className={style.cardTitle}>  {dataSheetData.title5}</h5>
                        <hr className={style.hr}/>
                        <p className={style.paragraph}>
                            <p dangerouslySetInnerHTML={{ __html: dataSheetData.paragraph5 }}></p>
                        </p>
                    </div>  
                    } */}
                    
                                        
                </div>
                <div className={style.sibilingTwo}>
                    <div className={style.cardImage}>
                        <img 
                        className={style.imageMain}
                        src={dataSheetData.urlImgMain} alt="" />
                    </div>
                    {dataSheetData.textFooter1 && 
                        <div className={`${style.textFooterColor}`}>
                                Ref: {dataSheetData.textFooter1}
                        </div>
                    }
                    <div className={style.cardImage}>
                        <img 
                        className={style.imageSecondPage}
                        src={dataSheetData.urlImgCut} alt="" />
                    </div>
                    <div className={style.cardContainer} style={{ marginTop: '80px'}}>                                           
                    </div>
                </div>
            </div> 
            {/* <div className={style.footerFixed1}>                    
                <div className={`row justify-content-center mb-0 p-0 mt-1`}>                              
                            <div className={style.imageFooterFixed2}>
                                <img 
                                className={style.imageColor}
                                src={wwwLogo} alt="image" />
                            </div>             
                            <div className={style.mafirolCom}>.mafirol.com
                            </div>                
                </div>
            </div>  */}
            <div className={style.footerFixed1}>                        
                        <div className={`${style.textFooter1} ${style.footerFixed}`}> 
                            <b dangerouslySetInnerHTML={{ __html: dataSheetData.titleFooter2 }}></b>
                            <p dangerouslySetInnerHTML={{ __html: dataSheetData.subTitleFooter2 }}></p>
                            <p dangerouslySetInnerHTML={{ __html: dataSheetData.textFooter2 }}></p>
                        </div>
                        <div className="row justify-content-center mb-0 p-0 mt-1 ml-5">
                            {/* <div className={style.itemFooter}>
                                <img 
                                className={style.imageColor}
                                src={logoMafirol} alt="image" />
                            </div> */}
                            <div className={style.itemFooter}>
                                {/* <img 
                                className={style.imageColor}
                                src={wwwLogo} alt="image" /> */}
                            </div>     
                            <div className={style.mafirolCom}>www.mafirol.com
                            </div>                         
                        </div>
                </div>           
            </div>                    
        </div>        
           
                
                

           <button
            id="btn-print"
            className="btn btn-success btn-lg hidden"
            style={{ backgroundColor: 'white', color: 'black', textAlign: 'center' }}
            onClick={() => {
                generatePDF(1); // Inicie a geração do PDF com a primeira página
            }}
            >
                Gerar PDF e Imprimir
            </button> 
      
    </div>
    )
}

export default DataSheetType2

