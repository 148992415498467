import { BottomNavigationAction, Box, Chip, Container, Divider, Grid, Typography } from '@mui/material';
import style from './MostWanted.module.css';
import * as React from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { NameSpace } from '../../data/AppLanguage';
import { useTranslation } from 'react-i18next';
import Util from '../../utils/Util';
import { Link } from 'react-router-dom';

interface Teste {
    id: string;
    nome: string;
    description: string;
    imagem: string;    
}

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    maxWidth: 400,
  }));

const Teste: Teste[] = [
    { id: 'af7ba3bd-1a7a-4367-aa21-682e3aead8b8', nome: 'PSI II PINTADO', description: 'LÁCTEOS E CHARCUTARIA', imagem: "https://mmsirius.mafirol.info/Categories/Families/20240205153812946_eb566650-e153-4bc6-8e79-bef3709a275c.jpg" },
    { id: '9a895652-3ec1-4091-bddb-27b6899eb9c0', nome: 'PSI PLUS II PINTADO', description: 'LÁCTEOS E CHARCUTARIA', imagem: "https://mmsirius.mafirol.info/Categories/Families/20240205154147826_c2a52696-bfa5-4404-b613-51f9895dc892.jpg" },
    { id: '1a23f8b9-9df7-4aa9-9a86-9c95977ce36b', nome: 'PSI II PINTADO', description: 'PORTAS PLANAS ABRIR',  imagem: "https://siriusmedia.s3.eu-west-2.amazonaws.com/CustomerDataSheet/20240208114347089_efb89fb9-0551-4e94-8083-c6ebbe5799d5_269090.jpg" },
    { id: 'f0085e48-1554-4f92-8526-128d681d07b1', nome: 'GAMA', description: 'VIDROS CURVOS VENTILADA COM RESERVA', imagem: "https://siriusmedia.s3.eu-west-2.amazonaws.com/CustomerDataSheet/20240208110047412_27107452-c622-48cf-8ddf-bf775a3df1ef_245010.jpg" },
]

const MostWanted = () => {
    const { t, i18n } = useTranslation([NameSpace.COMMON, NameSpace.ROUTES]);
    const language = Util.splitLanguage(i18n.language, 0);
    const productFinalPath = t("productFinalItem", {
        ns: NameSpace.ROUTES,
      });
    

    return (        
        <Container maxWidth={'xl'} justifyContent={'center'} sx={{ mb: 10}}>       
            <Divider  sx={{ mb: 10, mt: 5}}>
                <Chip sx={{ fontSize: 25, fontWeight: 500, textTransform: 'uppercase', color: '#345c8d', backgroundColor: '#f2f2f2'}} label="Mais Procurados" size="medium" />
            </Divider> 
            {/* <Typography textAlign={'center'} my={3}> Mais Procurados</Typography>             */}
            <Grid container spacing={{ xs: 3, md: 3 }} columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }} justifyContent={'left'}>
                {Teste.map((dataSheet, index) => (
                    <Grid item key={index}
                    xs={6}
                    md={4}                      
                    lg={3}
                    xl={3}
                    >
                        <Link to={`/${language}/${productFinalPath}/${dataSheet.id}`}>
                            <Item>
                                <img src={dataSheet.imagem ?? ""} style={{ maxWidth: '100%'}}/>
                                <Typography component={'h1'} variant='body1'>
                                    {dataSheet.nome ?? ""}
                                </Typography>
                                <Typography component={'h1'} variant='body2' fontSize={10}>
                                    {dataSheet.description ?? ""}
                                </Typography>
                                {/* <BottomNavigationAction label="Nearby" icon={<LocationOnIcon />} /> */}
                            </Item>
                        </Link>                         
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
};

export default MostWanted;
