import { string } from "yup";
import { destroy, get, post, put } from "./Api";
import DataSheetEntitie from "../../models/DataSheetEntitie";
import DataSheetModelResponse from "../../models/DataSheetModelResponse";


const DataSheetService = {    
    getDataSheetById: async (id: string, language: string) => 
            get<DataSheetModelResponse>(`datasheet/datasheetbyid/${id}/${language}`),    

    getDataSheetsByFamily: async (id: string, language: string) => 
            get<DataSheetEntitie[]>(`datasheet/datasheetfamily/${id}/${language}`),
};

export default DataSheetService;