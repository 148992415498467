import { Box, Button, Container, IconButton, Typography } from "@mui/material";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Chip from '@mui/material/Chip';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./ProductVariantFinal.css";
import { useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import Util from "../../utils/Util";
import { useTranslation } from "react-i18next";
import { NameSpace } from "../../data/AppLanguage";
import DataSheetModelResponse from "../../models/DataSheetModelResponse";
import CustomerDataSheetService from "../../services/api/CustomerDataSheet";
import createTable from "./createtable";
import style from './PDFGenerator.module.css'
import ProductModelResponse from "../../models/ProductModelResponse";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import ProductAccessory from "../../components/ProductAccessory/ProductAccessory";
import ContactSpecificForm from "../../components/ContactSpecificForm/ContactSpecificForm";
import { CategoryModel } from "../../models/CategoryModel";
import { useDataSheet } from "../../context/ContactSpecific";


interface Settings {
    dots: boolean,
    speed: number,
    slidesToShow: number,
    slidesToScroll: number,
    infinite: boolean,
    autoplay: boolean,
    autoplaySpeed: number,
    prevArrow: any,
    nextArrow: any,
    responsive: any
}

const ProductVariantFinal = () => {
    const params = useParams<{path: string}>();
    const { t, i18n } = useTranslation([NameSpace.ROUTES, NameSpace.NAVIGATION]);
    const language = Util.splitLanguage(i18n.language, 0);
    const [dataSheet, setDataSheet] = useState<DataSheetModelResponse | undefined>(undefined);
    const [category, setCategory] = useState<CategoryModel>();
    const [imageSelected, setImageSelected] = useState<string>(dataSheet?.urlImgMain ?? "");
    const [productsAccessories, setProductsAccessories] = useState<ProductModelResponse[]>([]);
    const [expandIndex, setExpandIndex] = useState<number | null>(0);
    const [isSmallScreen, setIsSmallScreen] = useState<boolean>(false);
    const [hasFeatures,  setHasFeatures] = useState<boolean>(false);
    const contactFormRef = useRef<HTMLElement>(null);  
    const { dataSheetData, setDataSheetData } = useDataSheet();



    const askInformation = () => {
      if (contactFormRef.current) {
        contactFormRef.current.scrollIntoView({
          block: "start",
          behavior: "smooth",
        });
      }
    };
  

    useEffect(() => {
      setIsSmallScreen(window.innerWidth < 1200); // Verifique se a largura da tela é menor que 1200px ao carregar a página
      window.addEventListener("resize", () => {
          setIsSmallScreen(window.innerWidth < 1200); // Atualize o estado quando a tela for redimensionada
      });

      return () => {
          window.removeEventListener("resize", () => {});
      };
    }, []);


    useEffect(() => {
        if(params.path !== null){
            CustomerDataSheetService.getCustomerDataSheetById(params.path, language).then((res) => {
              const result = res.data;
                setDataSheet(res.data);
               
                const image = result?.urlImgMain ?? "";
                setImageSelected(image);

                setDataSheetData(result);
            })                        
        }
    },[params.path])

    useEffect(() => {
        if(dataSheet && dataSheet.dataTable)
        {
            createTable(dataSheet?.dataTable, language.toUpperCase());  

            // setHasFeatures(dataSheet.dataTable ? true);
        }
    }, [dataSheet?.dataTable])

    useEffect(() => {
      if(dataSheet?.code != null && dataSheet.code != "")
      {
        CustomerDataSheetService.getProductsByDataSheetCode(dataSheet.code, language).then((res) => {
          setProductsAccessories(res.data);

        }).catch(err => console.log(err))
      }
    },[dataSheet])

    const handleImageChange = (newImage: string) => {
      setImageSelected(newImage);
    };
    
    const NextArrow = ({ onClick }: { onClick: () => void }) => (
      <IconButton onClick={onClick} size="large" sx={{ position: 'absolute', top: '50%', right: -25, transform: 'translateY(-50%)' }}>
        <MdKeyboardArrowRight />
      </IconButton>
    );
  
    const PrevArrow = ({ onClick }: { onClick: () => void }) => (
      <IconButton onClick={onClick} size="large" sx={{ position: 'absolute', top: '50%', left: -25, transform: 'translateY(-50%)' }}>
        <MdKeyboardArrowLeft />
      </IconButton>
    );

    const settings: Settings = {
        dots: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        autoplay: false,
        autoplaySpeed: 1000,
        nextArrow: <NextArrow />,
      
        prevArrow: <PrevArrow />,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
    };

  

    const handleProductClick = (index: number) => {
        setExpandIndex(prevIndex => (prevIndex === index ? null : index));
    }

    
    return(
      <>
      <Container maxWidth={"1500px"}  sx={{ maxWidth: "1500px" , height: "auto" , paddingTop: 20}}>
           <Box className="contentProduct" width={'100%'}>
             
              {!isSmallScreen && 
                <Box display="flex" flexDirection="column" className="thumbnailContainer" >
                   {dataSheet?.urlImgMain && 
                      <div  className={'thumbnail'} onClick={() => handleImageChange(dataSheet?.urlImgMain ?? "")} >
                          <img src={dataSheet?.urlImgMain} alt="image main"  className="thumbnailImage" />
                      </div>
                   }
                   
                    {dataSheet?.urlImgCut && 
                      <div  className={'thumbnail'} onClick={() => handleImageChange(dataSheet?.urlImgCut ?? "")}>
                          <img src={dataSheet?.urlImgCut} alt="image cut" className="thumbnailImage" />
                      </div>                    
                    }
                </Box>
              }


                <Box className="product-image" display={'block'}>
                    <img 
                    src={imageSelected}
                    alt="image"
                    style={{ maxWidth: "100%"}}
                    />                  
                     {isSmallScreen && 
                        <Box display="flex" className="thumbnailContainer" style={{flexDirection: 'row', paddingTop: '20px', justifyContent: 'center'}}>
                          {dataSheet?.urlImgMain && 
                              <div  className={'thumbnail'} onClick={() => handleImageChange(dataSheet?.urlImgMain ?? "")} >
                                  <img src={dataSheet?.urlImgMain} alt="image main"  className="thumbnailImage" />
                              </div>
                          }
                          
                            {dataSheet?.urlImgCut && 
                              <div  className={'thumbnail'} onClick={() => handleImageChange(dataSheet?.urlImgCut ?? "")}>
                                  <img src={dataSheet?.urlImgCut} alt="image cut" className="thumbnailImage" />
                              </div>                    
                            }
                        </Box>
                      }
                     <div className={'cardContainer'}>
                    {dataSheet?.listColor1 != 0 && dataSheet?.listColor1 && 
                    <>
                    {/* <h5 className={'cardTitle'}>{dataSheet?.ExternColorsDescription}Cores externas</h5>
                    <hr className={'hr'}/> */}
                    <div className="row">
                            {dataSheet?.listColor1 && dataSheet?.listColor1.map((item: any, index: any) => {
                                return(
                                    <div  className={'cardColor'}>
                                        <img 
                                        key={index}
                                        className={'imageColor'}
                                        src={item} alt="image" />
                                    </div>
                                );
                            
                            })}                            
                    </div>
                    </>                             
                    }
                
                </div>
                </Box>
                <Box className="product-info" display={'block'}>
                    <Box className="product-header">
                            <Typography component={'h2'} variant="body2" sx={{ textTransform: "uppercase" , color: "#8B939F"}}>
                                {dataSheet?.subtitle}
                            </Typography>
                            <Typography 
                                className="title"
                                component={'h1'} 
                                variant="h1" 
                                sx={{ 
                                    textTransform: "uppercase" ,
                                    color: "#28487A", 
                                    fontSize: "35px",
                                    fontWeight: 700
                                }}
                            >
                                {dataSheet?.title}
                            </Typography>
                            <Typography component={'p'} variant="body2" sx={{ fontSize: "15px"}}>
                               {dataSheet?.subtitleText}    
                            </Typography>
                    </Box>
                    <Box className="product-body" sx={{ paddingBottom: "2em" }}>
                            <Typography 
                                className="subtitle"
                                component={'h2'} 
                                variant="h2" 
                                sx={{ 
                                    textTransform: "uppercase" ,
                                    color: "#345C8D", 
                                    fontSize: "30px",
                                    fontWeight: 400,
                                    paddingTop: "1.8em"
                                }}
                            >
                                INFORMAÇÃO DO PRODUTO
                            </Typography>
                            <Box className="list-options pt-4">                            
                                <span dangerouslySetInnerHTML={{ __html: dataSheet?.paragraph1 }}></span>
                            </Box>
                    </Box>               
                    <Box 
                        sx={{ borderTop: "1px solid #000000", paddingTop: "5px", justifyContent: 'space-between', textAlign: 'justify' }}
                    >
                            <Button 
                            startIcon={<FileDownloadOutlinedIcon sx={{ color: "#000000"}}/>} 
                            sx={{ mx: 2 }}
                            >
                                <Typography sx={{ color: "#000000", ":hover" : { color: "#345C8D" } }}>
                                    Ficha técnica
                                </Typography>                            
                            </Button>

                            <Button 
                            endIcon={<Chip label={productsAccessories.length}  sx={{ color: "#ffffff", backgroundColor: "#345C8D",  }}/>} 
                            sx={{ mx: 2 }}
                            href="#acessory"
                            >
                                <Typography sx={{ color: "#000000", ":hover" : { color: "#345C8D" } }}>
                                    Acessórios
                                </Typography>   
                            </Button>

                            <Button 
                            sx={{ mx: 2 }}
                            >
                                <Typography sx={{ color: "#000000", ":hover" : { color: "#345C8D" } }}>
                                    Saber Mais
                                </Typography>   
                            </Button>
                    </Box>
                </Box>
           </Box>
           
           {/* {
            hasFeatures ? (
              <> */}
                <Typography 
                  className="shadow"
                  data-content="Informação técnica"
                  component={'h1'} 
                  variant={'h3'} 
                  sx={{ 
                  textTransform: "uppercase",                     
                  fontFamily: 'Noto Sans Tirhuta, sans-serif',
                  boxShadow: 'none !important',
                  marginTop: 20
                  }}>
                   {t("technicalInformation", { ns: NameSpace.NAVIGATION})}                   
                </Typography>
                <hr style={{ margin: '10px 0 50px 0' }}/>
              {/* </>             
            ) : null
           }
           */}
          
           <Box
            sx={{ width: '100%', marginTop: '50px !important', justifyContent: 'center', textAlign: 'center', display: 'flex', mb: 5}}
           >
                <div 
                    className={`${style.content_table} ${style.backgroundImageColor}`}
                >
                    <div id="tableId" 
                    className={`${style.table} ${style.backgroundImageColor}`}
                    ></div>                               
                </div> 
           </Box>


           <Box id="acessory"></Box>
           {
             productsAccessories.length ? (
          <Box className="content" sx={{ mt: 20}}>              
                <Typography 
                    className="shadow"
                    data-content="Acessórios"
                    component={'h1'} 
                    variant={'h3'} 
                    sx={{ 
                    textTransform: "uppercase",                     
                    fontFamily: 'Noto Sans Tirhuta, sans-serif',
                    boxShadow: 'none !important',
                    }}>
                      {t("accessories", { ns: NameSpace.NAVIGATION})}                      
                </Typography>
                <hr style={{ margin: '2px 0 50px 0' }}/>
                

                <ProductAccessory products={productsAccessories}  />

               
               
           </Box>
           ) : null
          } 
        </Container>
        <ContactSpecificForm
              // dataSheetProduct={dataSheet}
              className="mt-10"
              ref={contactFormRef}
          />         
      </>
        
    );

};

export default ProductVariantFinal;


  