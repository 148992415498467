import { Container, Row, Col, Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { CategoryModel } from "../../models/CategoryModel";

import { NameSpace } from "../../data/AppLanguage";

import CategoryItem from "../CategoryItem/CategoryItem";

import "./Category.css";
import CategoryItemCommercial from "../CategoryItemCommercial/CategoryItemCommercial";
import { Grid } from "@mui/material";




interface Props {
  category?: CategoryModel;
  nameClassName?: string;
  borderBottom?: boolean;
  overlay?: boolean;
  scaleImage?: boolean;
  background?: boolean;
  isCommercial?: boolean; 
}

const Category = (props: Props) => {
  const { t } = useTranslation([NameSpace.PAGES]);

  return (
    <article className="pb-5 mb-8 px-5">
      <Container className="category">
        <Row className="category-wrapper">
          {props.category?.successors.length && !props.isCommercial ? (
            <Grid container
            // spacing={4}
            justifyContent={'center'}
            columns={12}
            >
              {props.category?.successors.map((item, index) => {
                return (
                  <Grid
                    item
                    xs={6}
                    md={4}                      
                    lg={4}
                    xl={3}
                    key={index}
                    className="category-wrapper-col"
                  >
                    <CategoryItem
                      category={props.category}
                      primaryMedia={item.media}
                      name={item.name}
                      // review={{ text: item.review, textField: item.reviewField }}
                      path={item.path}
                      style={{
                        cardClassName: "mx-auto",
                        nameClassName: props.nameClassName,
                        // borderBottom: props.borderBottom,
                        overlay: props.overlay,
                        scaleImage: props.scaleImage,
                        background: props.background,
                      }}
                    />
                  </Grid>
                );
            })}
            </Grid>
           
          ) : !props.isCommercial && (
            <Col>
              <Alert variant="warning" className="category-wrapper-no-content">
                {t("noContent")}
              </Alert>
            </Col>
          )}


          {/* Se for comercial renderizar esse componete item */}

          {props.category?.successors.length && props.isCommercial ? (
            <Grid container
            spacing={4}
            columns={12}
            > 
              {props.category?.successors.map((item, index) => {
                  return (
                    <Grid item
                      xs={6}
                      md={4}                      
                      lg={3}
                      xl={2}
                      key={index}
                      className="category-wrapper-col"                     
                    >
                      <CategoryItemCommercial
                        category={props.category}
                        primaryMedia={item.media}
                        name={item.name}                    
                        path={item.path}
                        style={{
                          cardClassName: "mx-auto",
                          nameClassName: props.nameClassName,                      
                          overlay: props.overlay,
                          scaleImage: props.scaleImage,
                          background: props.background,
                        }}
                      />
                    </Grid>
                  );
                })
              }
            </Grid>
          ) : props.isCommercial && (
            <Col>
              <Alert variant="warning" className="category-wrapper-no-content">
                {t("noContent")}
              </Alert>
            </Col>
          )}
        </Row>
      </Container>
    </article>
  );
};

export default Category;
